import React from "react";
import Img from "gatsby-image";
type Props = {
  imageInfo: {
    alt: string;
    childImageSharp: any;
    image: string | any;
  };
};
const PreviewCompatibleImage = (props: Props) => {
  const { imageInfo } = props
  const { alt = "", childImageSharp, image } = imageInfo;

  const imageProps = {
    style: { maxHeight: '60vh'},
    imgStyle: {objectFit: 'contain'},
    ...props,
  }


  if (!!image && !!image.childImageSharp) {
    return <Img {...imageProps} fadeIn fluid={image.childImageSharp.fluid} alt={alt} />;
  }

  if (!!childImageSharp) {
    return <Img {...imageProps} fadeIn fluid={childImageSharp.fluid} alt={alt} />;
  }

  if (!!image && typeof image === "string")
    return <img style={{ width: "100%" }} src={image} alt={alt} />;

  return null;
};

export default PreviewCompatibleImage;
