import styled, { css } from 'styled-components';

export const SLIDE_INTERVAL = 500;

export const ArrowButton = styled.button<{ arrowType: 'right' | 'left'; paddingWidth: number }>`
  font-size: 1.5em;
  background: transparent;
  position: absolute;
  z-index: 10;
  top: 50%;
  ${p =>
    p.arrowType === 'left' &&
    css`
      left: ${p.paddingWidth}px;
      transform: translate(-150%, -50%);
    `}
  ${p =>
    p.arrowType === 'right' &&
    css`
      right: ${p.paddingWidth}px;
      transform: translate(150%, -50%);
    `}

  &:hover {
    > svg {
      transform: scale(1.2);
    }
  }
`;

export const Dot = styled.div`
  width: 8px;
  height: 8px;s
  background-color: #fff;
  opacity: 0.5;
  border-radius: 50%;
`;

export const Dots = styled.ul`
  position: absolute;
  z-index: 10;
  left: 50%;
  bottom: 0.75rem;
  transform: translate(-50%);
  height: 1rem;

  > li {
    cursor: pointer;
    width: 1.5rem;
    height: 1rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  > .slick-active {
    > ${Dot} {
      transform: scale(1.05);
      opacity: 1;
    }
  }
`;

export const Wrapper = styled.div<{ stacked: boolean }>`
  .slick-slider {
    position: relative;
    touch-action: pan-y;
    box-sizing: border-box;
    user-select: none;
  }

  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    transition: transform ${SLIDE_INTERVAL}ms ease-in-out, opacity ${SLIDE_INTERVAL}ms ease-in-out;
    transform: scale(0.85);
    opacity: 0.6;

    &.slick-center {
      transform: scale(1);
      opacity: 1;
    }

    > div {
      height: 100%;
      display: flex;
      justify-content: center;
    }
  }
`;
