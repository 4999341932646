import React, { useRef } from 'react';
import useComponentSize from '@rehooks/component-size';

import Slider from 'react-slick';
import * as S from './SlickSliderStyles';

export type ArrowProps = CustomArrowProps & {
  arrowType: 'left' | 'right';
  paddingWidth: number;
};

export const Arrow: React.FC<ArrowProps> = props => (
  <S.ArrowButton
    {...props}
    data-test={`slider-arrow-${props.arrowType}`}
    modifier="hollow"
    variant="default-invert"
    noShadow
  >
    {props.children}
  </S.ArrowButton>
);

type ChildFunc = (p: { currentIndex: number; slickSlider: Slider }) => React.ReactNode;

type Props = {
  children: React.ReactNode | ChildFunc;
} & typeof defaultProps;

export const defaultProps: { settings: Settings; stacked: boolean } = {
  settings: {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    speed: S.SLIDE_INTERVAL,
    pauseOnHover: true,
    pauseOnDotsHover: true,
    autoplay: true,
    pauseOnFocus: true,
    autoplaySpeed: 5000,
  },
  stacked: false,
};

export const SlickSlider = (props: Props) => {
  const slickSlider = useRef<Slider>(null);
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const wrapper = useRef<HTMLDivElement>(null);
  const { width } = useComponentSize(wrapper);
  const paddingWidth = props.stacked ? width / 16 : width / 4;

  const resetSliderTimer = () => {
    if (slickSlider?.current && props.resetOnTouch) {
      slickSlider.current.slickPause();
      slickSlider.current.slickPlay();
    }
  };

  const settings = {
    ...props.settings,
    nextArrow: (
      <></>
      // <Arrow
      //   arrowType="right"
      //   paddingWidth={paddingWidth}
      //   onClick={() => {
      //     if (slickSlider && slickSlider.current) {
      //       resetSliderTimer();
      //       slickSlider.current.slickNext();
      //     }
      //   }}
      // >
      //   Next
      //   </Arrow>
    ),
    prevArrow: (
      <></>
      // <Arrow
      //   arrowType="left"
      //   paddingWidth={paddingWidth}
      //   onClick={() => {
      //     if (slickSlider && slickSlider.current) {
      //       resetSliderTimer();
      //       slickSlider.current.slickPrev();
      //     }
      //   }}
      // >
      //   Prev
      // </Arrow>
    ),
    appendDots: dots => <S.Dots>{dots}</S.Dots>,
    customPaging: () => <S.Dot />,
    onSwipe: resetSliderTimer,
    centerPadding: `${paddingWidth}px`,
  };

  return (
    <S.Wrapper ref={wrapper} stacked={props.stacked}>
      <Slider
        beforeChange={(_, newIndex) => {
          setCurrentIndex(newIndex);
        }}
        ref={slickSlider}
        {...settings}
      >
        {typeof props.children === 'function'
          ? props.children({ currentIndex, slickSlider: slickSlider.current })
          : props.children}
      </Slider>
    </S.Wrapper>
  );
};

SlickSlider.defaultProps = defaultProps;

export default SlickSlider;
