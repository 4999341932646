import React from "react";
import { Link, graphql } from "gatsby";

import Layout from "../components/Layout";
import styled from "styled-components";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import SlickSlider from '../components/SlickSlider'
import Seo from '../components/Seo'
export const Date = styled.span`
  font-style: italic;
`;

export const PhotoInfoWrap = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  align-items: center;

  h1 {
    margin: 0;
  }

  padding: 1rem 4rem;
  @media (max-width: 768px) {
    padding: 1rem 2rem;
  }
`;
export const BottomWrap = styled.div`
  display: flex;
  > *:first-child {
    padding-right: 5rem;
  }
  padding: 1rem 4rem;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 1rem 2rem;
    > *:first-child {
      padding-right: 0;
      padding-bottom: 2rem;
    }
  }
`;

export const DescriptionWrap = styled.div`
  max-width: 600px;

  p {
    margin-bottom: 0.5rem;
  }
`;

export const TagsWrap = styled.div`
  font-size: 0.8rem;
  align-self: flex-end;
`;

export const StyledTag = styled(Link)`
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const ImageSeries = styled.div`
  * + * {
    padding-bottom: 2rem;
  }
`;

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};

const slideShowSettings = {
  arrows: false,
  dots: false,
  infinite: true,
  speed: 200,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  fade: true,
  autoplaySpeed: 300,
  pauseOnHover: true,
}

export const TitleWrap = styled.h1`
  padding-bottom: 1rem;
`;

const SliderWrap = styled.div`
  overflow: hidden;
  pointer-events: none;
`;
const Slide = styled.div`
  max-width: 40rem;
`;

export const ImagePageTemplate = (props) => {
  const { node } = props.allMarkdownRemark.edges[0];
  const { title, description, year, tags } = node.frontmatter;
  if(!node) return null;

  return (
    <>
    <Seo title={title} description={node.html.replace(/(<([^>]+)>)/gi, "")} />
      <div>
        {node.frontmatter.featuredimage?.length > 10 ? (
          <div class={node.frontmatter.featuredimage?.length > 10 && 'slide-show'}>
            <SliderWrap>
              <SlickSlider settings={node.frontmatter.featuredimage?.length > 10 ? slideShowSettings : settings}>
                {node.frontmatter.featuredimage.map((image) => (
                  <Slide>
                    <PreviewCompatibleImage
                      imageInfo={{
                        image
                      }}
                    />
                  </Slide>
                ))}
              </SlickSlider>
            </SliderWrap>
          </div>
        ) : (
          <ImageSeries>
            {node.frontmatter.featuredimage?.map((image, i) => (
              <div key={i}>
                <PreviewCompatibleImage
                  imageInfo={{
                    image
                  }}
                />
              </div>
            ))} 
          </ImageSeries>
        )}
        <PhotoInfoWrap>
          <TitleWrap>{title}</TitleWrap>
          {/* <TagsWrap>
            {tags && "Tags - "}
            {tags?.map((tag, i) => (
              <StyledTag key={i} to={`/tags/${tag}`}>
                {tag}
                {i + 1 !== tags.length && ", "}
              </StyledTag>
            ))}
          </TagsWrap> */}
        </PhotoInfoWrap>
        <BottomWrap>
          <Date>{year}</Date>
          <DescriptionWrap>
            {description && (
              <>
                <p>
                  {description}
                </p>
                <br/>
              </>
            )}
            <div dangerouslySetInnerHTML={{__html:node.html}}/>
          </DescriptionWrap>
        </BottomWrap>
      </div>
    </>
  );
};

const ImagePage = ({ data }) => {
  return (
    <Layout>
      <ImagePageTemplate {...data} />
    </Layout>
  );
};
export const query = graphql`
  query ImagePost($id: String!) {
    allMarkdownRemark(filter: { id: { eq: $id } }) {
      edges {
        node {
          html
          fields {
            slug
          }
          frontmatter {
            title
            description
            year
            tags
            featuredimage {
              childImageSharp {
                fluid(maxWidth: 1400, quality: 100) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default ImagePage;
